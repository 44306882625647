import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const SpinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
`;

export const WtlSpinner = (props) => (
  props.spinning ? (
    <SpinWrapper>
      <Spin
        tip={props.text}
        spinning={props.spinning}
      />
    </SpinWrapper>
  ) : null
);
