import React from 'react';
import styled from 'styled-components';
import { Layout, Row, Col } from 'antd';

const {
  Sider,
  Content
} = Layout;

export const WtlPanelHorizontal = 'horizontal';
export const WtlPanelVertical = 'vertical';

export const WtlPanelNote = ({text, icon} = {}) => {
  return (
    <div style={{ textAlign: 'center', fontSize: 10, opacity: 0.9, padding: 10, cursor: 'default' }}>
      {icon && <div className={`far fa-${icon}`} />} {text}
    </div>
  );
};

const PanelToggleHorizontal = styled.div`
  position: relative;
  width: 100%;
  border-bottom: solid 1px #ccc;
  background-color: #eee;
  font-size: 10px;
  cursor: ${p => p.resizable ? 'ns-resize' : 'default'};
`;

const PanelToggleSubpanel = styled.div`
  display: inline-block;
  padding: 4px 6px;
  text-transform: capitalize;
  border-right: solid 1px rgba(0, 0, 0, .1);
  ${p => p.selected && 'background-color: rgba(0, 0, 0, .1);'}
  cursor: pointer;

  &:hover {
    background-color: #ccc;
  }
`;

export class WtlPanel extends React.Component {
  constructor(props) {
    super(props);

    const { subpanels } = props;

    this.state = {
      collapsed: props.defaultCollapsed || false,
      subpanelIndex: props.openSubpanel || 1,
      cacheOpenSubpanel: null,
      expandedHeight: 0,
      adjustHeight: false,
      adjustHeightDetails: {}
    };
  }

  componentDidMount() {
    this.updateVisibleSubpanel(this.props.openSubpanel);
    this.setState({ cacheOpenSubpanel: this.props.openSubpanel });
  }

  componentDidUpdate() {
    if (this.props.openSubpanel !== this.state.cacheOpenSubpanel) {
      this.updateVisibleSubpanel(this.props.openSubpanel);
      this.setState({ cacheOpenSubpanel: this.props.openSubpanel });
    }
  }

  getPanelHeight(id) {
    const { subpanels } = this.props;

    return (subpanels[id - 1] && subpanels[id - 1].height) || 200;
  }

  adjustPanelHeight() {
    if (this.state.adjustHeight) {
      const { originH, y } = this.state.adjustHeightDetails;
      const dy = (y - window.mouseY);

      if (Math.abs(dy) < 5) {
        return;
      }

      this.state.expandedHeight = Math.max(0, originH + dy);
      this.forceUpdate();
    }
  }

  updateVisibleSubpanel(id) {
    if (!id || isNaN(id)) {
      return;
    }

    if (id === this.state.subpanelIndex && !this.state.collapsed) {
      return;
    }

    const _id = parseFloat(id);

    this.setState({ subpanelIndex: _id, collapsed: false, expandedHeight: this.getPanelHeight(_id) });
  }

  onCollapse(collapsed) {
    if (typeof this.props.onCollapse === 'function') {
      this.props.onCollapse(collapsed);
    }
  }

  renderPanelToggleSubpanel(id, index) {
    const { collapsed, subpanelIndex } = this.state;

    if (id === -1) {
      return (
        <PanelToggleSubpanel
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            this.setState({
              collapsed: !collapsed,
              expandedHeight: this.getPanelHeight(id)
            });

            this.onCollapse(!collapsed);
          }}
          style={{ float: 'right' }}
        >
          <i className="fa fa-bars" />
        </PanelToggleSubpanel>
      )
    }

    const isSelected = index === subpanelIndex;

    return (
      <PanelToggleSubpanel
        selected={isSelected}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.setState({
            subpanelIndex: index,
            collapsed: isSelected ? !collapsed : false,
            expandedHeight: this.getPanelHeight(index)
          });

          this.onCollapse(!collapsed);
        }}
      >
      {isSelected && (
        collapsed ?
          <i className="fas fa-caret-up" /> :
          <i className="fas fa-caret-down" />
      )} {id}
      </PanelToggleSubpanel>
    );
  }

  renderHorizontalPanel() {
    const { collapsed, subpanelIndex, expandedHeight, adjustHeight } = this.state;
    const { subpanels, resizable } = this.props;

    // NOTE Temporary, fix panel resizing
    const panelHeight = (subpanels[subpanelIndex - 1] && subpanels[subpanelIndex - 1].height) || 200;

    const panels = [
      { id: -1 }
    ].concat(subpanels).filter(Boolean);

    return (
      <Content
        style={{
          position: 'relative',
          background: '#fff',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          minHeight: collapsed ? 24 : panelHeight,
          maxHeight: collapsed ? 24 : panelHeight,
          borderTop: 'solid 1px #333',
          transition: 'min-height .3s ease, max-height .3s ease'
        }}
      >
        <Row>
          <Col span={24}>
            <PanelToggleHorizontal>
              {panels.map((subpanel, index) => this.renderPanelToggleSubpanel(subpanel.id, index))}
            </PanelToggleHorizontal>
          </Col>
        </Row>
        {!collapsed && (
          <div
            style={{
              padding: 12,
              overflow: 'hidden scroll',
              height: 'calc(100% - 24px)'
            }}
          >
            {!subpanels ? this.props.children : subpanels[subpanelIndex - 1].panel}
          </div>
        )}
      </Content>
    )
  }

  renderVerticalPanel() {
    return (
      <Sider
        width={450}
        style={{
          position: 'relative',
          background: '#fff',
          overflowY: 'scroll',
          overflowX: 'hidden',
          boxSizing: 'border-box',
          borderRight: 'solid 1px #000'
        }}
      >
        {this.props.children}
      </Sider>
    );
  }

  render() {
    const { layout } = this.props;

    if (layout === WtlPanelHorizontal) {
      return this.renderHorizontalPanel();
    }

    return this.renderVerticalPanel();
  }
}