import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import { VertexShaderPCSS } from '../../../common-schema/shaders/pcss/vertex';
import { FragmentShaderPCSS } from '../../../common-schema/shaders/pcss/fragment';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const CanvasContainer = styled.div`
  &, canvas {
    outline: none;
    cursor: default;
  }
`;

export class Wtl3DScene extends React.Component {
  constructor(props) {
    super(props);

    this.sceneRef = React.createRef();

    this.active = 0;
    this.renderer = null;
    this.composer = null;
    this.scene = null;
    this.camera = null;
    this.controls = null;
    this.effects = {};
    this.frameRate = 10;
    this.assets = [];
  }

  componentDidMount() {
    this.initScene();
    this.addResizeListener();
  }

  componentDidUpdate() {
    if (this.active === 0) {
      this.initScene();
    }
  }

  componentWillUnmount() {
    this.active = -1;

    const cleanUp = (item) => {
      if (item && item.dispose) {
        item.dispose();
      }
    };

    this.assets.forEach(asset => {
      cleanUp(asset);
    });

    if (this.scene) {
      this.scene.traverse(mesh => {
        cleanUp(mesh.geometry);
        cleanUp(mesh.material);
      });

      cleanUp(this.scene.background);
      cleanUp(this.scene.environment);

      while (this.scene.children[0]) {
        this.scene.remove(this.scene.children[0]);
      }
    }

    cleanUp(this.renderer);
    cleanUp(this.controls);
  }

  addResizeListener() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  initScene() {
    if (typeof window === 'undefined' || this.active !== 0 || !this.sceneRef.current) {
      return;
    }

    const { width, height, onSceneReady, renderQuality, rendererOptions, aoQuality } = this.props;

    this.active = 1;

    if (!this.scene) {
      this.scene = new Three.Scene();
      this.sceneRoot = new Three.Group();
      this.scene.add(this.sceneRoot);
    }

    if (!this.renderer) {
      this.renderer = new Three.WebGLRenderer({
        antialias: true,
        ...(rendererOptions || {})
      });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(width || window.innerWidth, height || window.innerHeight);
      this.renderer.toneMapping = Three.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      this.renderer.outputEncoding = Three.sRGBEncoding;
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = Three.PCFSoftShadowMap;

      requestAnimationFrame(() => this.onAnimationStep());
    }

    if (!this.camera) {
      this.camera = new Three.PerspectiveCamera(
        40,
        (width || window.innerWidth) / (height || window.innerHeight),
        1,
        2000
      );
      this.camera.position.set(0, 40, 140);
    }

    this.sceneRef.current.innerHTML = '';
    this.sceneRef.current.appendChild(this.renderer.domElement);

    if (!this.composer && renderQuality === 2) {
      this.composer = new EffectComposer(this.renderer);

      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      let saoPass;

      if (aoQuality) {
        saoPass = new SAOPass(this.scene, this.camera, false, true);
        saoPass.params.saoBias = 1.0;
        saoPass.params.saoIntensity = 1.0;
        saoPass.params.saoScale = 1000.0;
        saoPass.params.saoKernelRadius = 10.0;
        saoPass.params.saoMinResolution = 0.0;
        saoPass.params.saoBlur = true;
        saoPass.params.saoBlurRadius = 1.0;
        saoPass.params.saoBlurStdDev = 4.0;
        saoPass.params.saoBlurDepthCutoff = 0.01;
        this.composer.addPass(saoPass);
      }

      const bloomPass = new UnrealBloomPass(
        new Three.Vector2(width, height),
        0.1,
        0.3,
        0.8
      );
      this.composer.addPass(bloomPass);

      const fxaaEffect = new ShaderPass(FXAAShader);
      fxaaEffect.uniforms.resolution.value.set(1 / width, 1 / height);
      this.composer.addPass(fxaaEffect);

      this.effects = {
        ao: saoPass,
        fxaa: fxaaEffect,
        bloom: bloomPass,
      };
    }

    if (!this.controls) {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    }

    if (onSceneReady) {
      onSceneReady({
        renderer: this.renderer,
        scene: this.scene,
        camera: this.camera,
        root: this.sceneRoot,
        canvas: this.renderer.domElement,
        controls: this.controls,
        effects: this.effects,
        assets: this.assets
      });
    }

    this.active = 2;
  }

  onAnimationStep() {
    if (this.active !== 2) {
      return;
    }

    const { onAnimationStep } = this.props;

    if (onAnimationStep) {
      onAnimationStep({
        renderer: this.renderer,
        scene: this.scene,
        camera: this.camera,
        root: this.sceneRoot,
        canvas: this.renderer.domElement,
        controls: this.controls,
        effects: this.effects,
        assets: this.assets
      });
    }

    if (this.composer) {
      this.composer.render();
    } else {
      this.renderer.render(this.scene, this.camera);
    }

    if (this.controls) {
      this.controls.update();
    }

    if (this.active !== 2) {
      return;
    }

    setTimeout(() => {
      requestAnimationFrame(() => this.onAnimationStep());
    }, 1000 / this.frameRate);
  }

  onWindowResize() {
    const { width, height } = this.props;

    if (width || height) {
      return;
    }

    if (this.active === 2) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);

      if (this.composer) {
        this.composer.setSize(width, height);
      }
    }

    this.forceUpdate();
  }

  render() {
    const { style } = this.props;

    return (
      <CanvasContainer
        ref={this.sceneRef}
        key={1}
        style={{
          position: 'relative',
          maxWidth: '100%',
          width: '100%',
          outline: 'none',
          overflow: 'hidden',
          ...(style || {})
        }}
        onMouseEnter={() => {
          this.frameRate = 40;
        }}
        onMouseOver={() => {
          this.frameRate = 40;
        }}
        onMouseOut={() => {
          this.frameRate = 10;
        }}
        onMouseLeave={() => {
          this.frameRate = 10;
        }}
      />
    );
  }
}