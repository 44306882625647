import React from 'react';
import styled from 'styled-components';

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(211, 233, 255, .5);
  z-index: 9999;
`;

export const WtlDropZone = (props) => (
  <AbsoluteWrapper
    onDrop={(event) => {
      event.preventDefault();

      if (props.onUpload) {
        props.onUpload(event);
      }

      if (props.onClose) {
        props.onClose();
      }
    }}
    onDragOver={(event) => {
      event.preventDefault();
    }}
    onDragEnter={(event) => {
      event.preventDefault();
    }}
    onDragLeave={(event) => {
      event.preventDefault();

      if (props.onClose) {
        props.onClose();
      }
    }}
  >
  </AbsoluteWrapper>
);
