import React from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, message } from 'antd';
import { WtlTooltip } from './wtl-tooltip';
import { callApi } from '../../../config';
import { authTokenKey } from '../../services/editor.service';

const FileGrid = styled(Row)``;

const FileGridItem = styled(Col)`
  @keyframes blankFade {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1.0;
    }
  }

  @keyframes loadingFade {
    from {
      opacity: 1.0;
    }

    to {
      opacity: 0.0;
    }
  }

  height: 120px;
  margin-bottom: 4px;

  & > div {
    position: relative;
    margin: 4px;
    border: solid 4px #ccc;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: border-color .3s ease, color .3s ease, opacity .3s ease;
    overflow: hidden;
  }

  ${p => p.blank && `
    & > div {
      border-color: #1890ff;
      animation: blankFade 5s ease-in-out infinite alternate;
      cursor: pointer;
    }

    &:hover {
      & > div {
        opacity: 1;
        animation: none;
      }
    }

    .action {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      font-weight: bold;
      font-size: 11px;
      color: #1890ff;
      white-space: nowrap;
    }
  `}

  ${p => p.pending && `
    & > div {
      border-color: #eee;
      animation: blankFade 2s ease-in-out infinite alternate;
    }
  `}

  ${p => p.loading && `
    & > div {
      border-color: #eee;
      animation: loadingFade 1.5s ease-in-out ${p.order * 0.25}s infinite alternate;
    }
  `}

  ${p => p.preview && `
    &:hover {
      cursor: pointer;
      
      & > div {
        border-color: #1890ff;

        * {
          color: #1890ff;
        }
      }
    }
  `}
`;

const PreviewImage = styled.div`
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url(${p => p.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PreviewFont = styled.div`
  @font-face {
    font-family: "${p => p.url.split('.').splice(-2, 1)[0]}";
    src: url(${p => p.url}) format(${p => ({
      'ttf': '"truetype"',
      'otf': '"opentype"',
      'woff': '"woff"',
      'woff2': '"woff2"'
    })[p.url.split('.').splice(-1, 1)[0]]});
  }

  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "${p => p.url.split('.').splice(-2, 1)[0]}";
  font-size: 32px;
  line-height: 0;
  transform: translateX(-50%) translateY(-50%);
`;

const PreviewMissing = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  white-space: nowrap;
`;

export class WtlFileUploadModal extends React.Component {
  constructor(props) {
    super(props);

    this.uploadInput = React.createRef();
    this.state = {
      loaded: false,
      files: []
    };
  }

  componentDidMount() {
    this.loadFileList();
  }

  async loadFileList() {
    const { filterTypes } = this.props;

    if (typeof window === 'undefined') {
      this.setState({
        loaded: true,
        files: []
      });

      return;
    }

    try {
      const { items } = await callApi(
        'project/upload',
        'GET',
        {
          f: filterTypes ? encodeURIComponent(filterTypes) : undefined
        },
        {
          authorization: `Bearer ${sessionStorage[authTokenKey]}`
        }
      );

      this.setState({
        loaded: true,
        files: (items || [])
          .filter(item => (item.split('.').splice(-2, 1) || [])[0] !== 'min')
      });
    } catch {
      message.error('Cannot access project files. Try again later.');
      return;
    }
  }

  render() {
    const {
      selection,
      onFileSelected,
      onFileUpload,
      onClose,
      inputProps
    } = this.props;
    const { files, loaded } = this.state;
    const displayedItems = (Math.floor(files.length / 6) + 1) * 6 - 1;

    return (
      <Modal
        title={
          <>
            Project Files
          </>
        }
        visible
        closable
        maskClosable
        width={768}
        footer={false}
        onCancel={() => onClose()}
      >
        {
          !loaded && (
            <FileGrid gutter={4}>
            {
              Array(displayedItems + 1).fill(0).map((_, index) => (
                <FileGridItem span={4} loading order={index}>
                  <div></div>
                </FileGridItem>
              ))
            }
            </FileGrid>
          )
        }
        {
          loaded && (
            <FileGrid gutter={4}>
              <FileGridItem
                span={4}
                blank
                onClick={() => {
                  if (this.uploadInput && this.uploadInput.current) {
                    this.uploadInput.current.value = '';
                    this.uploadInput.current.click();
                  }
                }}
              >
                <div>
                  <div className="action">
                    <i className="far fa-fw fa-plus" /> Add file
                  </div>
                </div>
              </FileGridItem>
              {
                Array(displayedItems).fill(0).map((_, index) => {
                  const file = files[index];

                  if (file) {
                    let fileMinPath = file.split('.');
                    const fileExtension = fileMinPath.splice(-1, 1)[0];

                    const isImage = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp'].includes(fileExtension);
                    const isFont = [ 'otf', 'ttf', 'woff', 'woff2' ].includes(fileExtension);

                    fileMinPath = fileMinPath.concat([ 'min', 'jpg' ]).join('.');

                    let preview;

                    if (isImage) {
                      preview = (
                        <PreviewImage url={fileMinPath} />
                      );
                    } else if (isFont) {
                      preview = (
                        <PreviewFont url={file} fontId={index}>
                          Aa
                        </PreviewFont>
                      )
                    } else {
                      preview = (
                        <PreviewMissing>
                          <div>
                            <i className="far fa-eye-slash" />
                          </div>
                          <div>
                            no preview
                          </div>
                        </PreviewMissing>
                      );
                    }

                    return (
                      <FileGridItem
                        span={4}
                        onClick={() => {
                          onFileSelected(file);
                        }}
                        preview
                      >
                        <WtlTooltip
                          placement="bottom"
                          title={`${file.length > 16 ? '...' : ''}${file.substr(-16)}`}
                        >
                          <div>
                            {preview}
                          </div>
                        </WtlTooltip>
                      </FileGridItem>
                    );
                  }

                  return (
                    <FileGridItem span={4} pending>
                      <div></div>
                    </FileGridItem>
                  );
                })
              }
            </FileGrid>
          )
        }
        <input
          ref={this.uploadInput}
          style={{ display: 'none' }}
          type="file"
          value={undefined}
          onChange={(e) => {
            onFileUpload(e, url => {
              this.state.files.unshift(url);
              this.forceUpdate();
            });
          }}
          {...inputProps}
        />
      </Modal>
    );
  }
}