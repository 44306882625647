import React from 'react';
import { Tooltip } from 'antd';

export const WtlTooltip = (props) => {
  if (props.disabled) {
    return (
      <>
        {props.children}
      </>
    );
  }

  return (
    <Tooltip {...props}>
      {props.children}
    </Tooltip>
  );
};
