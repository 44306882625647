import { message, Menu, Dropdown, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GCodeLoader } from 'three/examples/jsm/loaders/GCodeLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader';
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
import { AMFLoader } from 'three/examples/jsm/loaders/AMFLoader';
import { KMZLoader } from 'three/examples/jsm/loaders/KMZLoader';
import { AssimpLoader } from 'three/examples/jsm/loaders/AssimpLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { handleFileDelete } from '../variable-input';

const ShareWrapper = styled.div`
  ::selection, ::moz-selection {
    color: #fff;
    background-color: #108ee9;
  }

  position: absolute;
  bottom: calc(20% + ${p => p.offsetY || 0}px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 420px;
  box-sizing: border-box;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, .75);
  padding: 15px 20px;
  z-index: 99;

  input {
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, .1);
    border-radius: 20px;
    border: 0;
    padding: 10px;
    color: #eeeeee;
    outline: none;
    border: solid 1px transparent;
    direction: rtl;

    &:active, &:focus {
      outline: none;
      border: solid 1px #108ee9;
    }
  }

  &:hover {
    input {
      color: #ffffff;
    }
  }
`;

const ShareFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CopyIcon = styled.div`
  color: #ffffff;
  margin-left: 10px;
  padding: 6px 8px;
  border-radius: 50%;
  box-sizing: border-box;

  &:hover {
    background-color: #108ee9;
    cursor: pointer;
  }
`;

const Note = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  background-color: #84b832;
  border-radius: 20px;
  color: #fff8de;
  transform: translateX(-50%);
  padding: 5px 15px;
  white-space: nowrap;
  text-shadow: 0px 1px 1px #111;
  border: solid 1px #111;
`;

const WarnLabel = styled.span`
  color: #ff9999;
`;

export const loaderMap = {
  'glb': [ 'GLTFLoader', 'GLTFLoader', GLTFLoader ],
  'gltf': [ 'GLTFLoader', 'GLTFLoader', GLTFLoader ],
  'fbx': [ 'FBXLoader', 'FBXLoader', FBXLoader ],
  'gcode': [ 'GCodeLoader', 'GCodeLoader', GCodeLoader ],
  'dae': [ 'ColladaLoader', 'ColladaLoader', ColladaLoader ],
  '3mf': [ 'ThreeMFLoader', '3MFLoader', ThreeMFLoader ],
  '3ds': [ 'TDSLoader', 'TDSLoader', TDSLoader ],
  'amf': [ 'AMFLoader', 'AMFLoader', AMFLoader ],
  'kmz': [ 'KMZLoader', 'KMZLoader', KMZLoader ],
  'assimp': [ 'AssimpLoader', 'AssimpLoader', AssimpLoader ],
  'obj': [ 'OBJLoader', 'OBJLoader', OBJLoader ],
};

const getTypeLoader = (extension) => {
  if (loaderMap[extension]) {
    const [ moduleName, path ] = loaderMap[extension];

    return `import { ${moduleName} } from 'https://unpkg.com/three@0.125.0/examples/jsm/loaders/${path}.js';`;
  } else {
    return '';
  }
}

const getTypeLoaderCode = (url, extension) => ({
  'glb': `new GLTFLoader().load(${url}, ({ scene: model }, animations) => {`,
  'gltf': `new GLTFLoader().load(${url}, ({ scene: model }, animations) => {`,
  'fbx': `new FBXLoader().load(${url}, (model) => {`,
  'gcode': `new GCodeLoader().load(${url}, (model) => {`,
  'dae': `new ColladaLoader().load(${url}, ({ scene: model }) => {`,
  '3mf': `new ThreeMFLoader().load(${url}, (model) => {`,
  '3ds': `new TDSLoader().load(${url}, (model) => {`,
  'amf': `new AMFLoader().load(${url}, (model) => {`,
  'kmz': `new KMZLoader().load(${url}, (model) => {`,
  'assimp': `new AssimpLoader().load(${url}, ({ object: model }) => {`,
  'obj': `new OBJLoader().load(${url}, (model) => {`,
})[extension] || '';

export const supportedTemplateTypes = Object.keys(loaderMap);
export const supportedImageTypes = [
  'png', 'jpeg', 'jpg', 'gif', 'tiff', 'bmp', 'hdr', 'ldr', 'webp'
];

const shareMenu = (url, extension, modelScale, owner = true) => (
  <Menu>
    <Menu.Item
      onClick={() => {
        navigator.clipboard.writeText(`https:${url}`).then(() => {
          message.success('URL copied to clipboard.');
        }).catch(() => {
          message.warning('Could not copy URL to clipboard. Please try again or copy manually.');
        });
      }}>
      <i className="fas fa-fw fa-copy" /> Copy URL
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        const [ user, code ] = url.split(/\/\/cdn\.wtlstudio\.com\/(.*?)\.wtlstudio\.com\/(.*)/).filter(Boolean);

        navigator.clipboard.writeText(
          `${window.location.origin}/share/?q=${user}&h=${code}`
        ).then(() => {
          message.success('Preview URL copied to clipboard.');
        }).catch(() => {
          message.warning('Could not copy preview URL to clipboard. Please try again or copy manually.');
        });
      }}>
      <i className="fas fa-fw fa-eye" /> Share Preview
    </Menu.Item>
    {supportedTemplateTypes.includes(extension) && <Menu.Item
      onClick={() => {
        const [ user, code ] = url.split(/\/\/cdn\.wtlstudio\.com\/(.*?)\.wtlstudio\.com\/(.*)/).filter(Boolean);

        const codeTemplate = `<script id="tc-model-viewer-${code}" type="module">
  import { WebGLRenderer, ACESFilmicToneMapping, sRGBEncoding, Scene, PerspectiveCamera, HemisphereLight, PointLight } from 'https://unpkg.com/three@0.125.0/build/three.module.js';
  ${getTypeLoader(extension)}
  import { OrbitControls } from 'https://unpkg.com/three@0.125.0/examples/jsm/controls/OrbitControls.js';

  (function () {
    const { scene, camera } = initScene(document.getElementById('tc-model-viewer-${code}'));

    ${getTypeLoaderCode(`'https:${url}'`, extension)}
      scene.add(model);

${!isNaN(modelScale) && modelScale !== 1.0 ?
`      const scale = ${modelScale};
      model.scale.multiplyScalar(scale);
      model.position.y -= scale / 2.0;
` : ``}
      camera.position.set(0.0, 0.0, 4.0);
    });

    function initScene (n) {
      const r = n.parentElement;
      const { width: w, height: h } = r.getBoundingClientRect();
      const rn = new WebGLRenderer({ alpha: true, antialias: true });
      rn.setSize(w, h);
      rn.setPixelRatio(window.devicePixelRatio || 1.0);
      rn.toneMapping = ACESFilmicToneMapping;
      rn.outputEncoding = sRGBEncoding;
      const s = new Scene();
      const c = new PerspectiveCamera(50, w / h);
      s.add(new HemisphereLight(0xffffcc, 0x3366aa, 2.0));
      const dl = new PointLight(0xffffcc, 1.0);
      dl.position.set(2.0, 2.0, 2.0); s.add(dl);
      new OrbitControls(c, rn.domElement);

      (function animate() {
        requestAnimationFrame(animate);
        rn.render(s, c);
      })();

      r.appendChild(rn.domElement);
      r.removeChild(n);

      return { root: r, renderer: rn, scene: s, camera: c };
    };
  })();
</script>
`;
        navigator.clipboard.writeText(codeTemplate).then(() => {
          message.success('HTML copied to clipboard.');
        }).catch(() => {
          message.warning('Could not copy HTML to clipboard. Please try again or copy manually.');
        });
      }}
    >
      <i className="fas fa-fw fa-code" /> Copy as HTML
    </Menu.Item>}
    {supportedImageTypes.includes(extension) && <Menu.Item
      onClick={() => {
        const codeTemplate = `
new TextureLoader().load('${url}', (texture) => {
  
});
`;
        navigator.clipboard.writeText(codeTemplate).then(() => {
          message.success('Texture code copied to clipboard.');
        }).catch(() => {
          message.warning('Could not copy texture code to clipboard. Please try again or copy manually.');
        });
      }}
    >
      <i className="fas fa-fw fa-cube" /> Copy as Texture
    </Menu.Item>}
    {supportedTemplateTypes.includes(extension) && <Menu.Item
      onClick={() => {
        const codeTemplate = `
import * as THREE from 'https://unpkg.com/three@0.125.0/build/three.module.js';;
${getTypeLoader(extension)}

const renderer = new THREE.WebGLRenderer();
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight);
let mesh;

renderer.setSize(window.innerWidth, window.innerHeight);
scene.background = new THREE.Color(0xeeeeee);
scene.add(new THREE.HemisphereLight(0xffffcc, 0x333399, 1.0));
camera.position.set(-2, 2, 10);

${getTypeLoaderCode(`'${url}'`, extension)}
  scene.add(model);

  ${!isNaN(modelScale) && modelScale !== 1.0 ? `model.scale.multiplyScalar(${modelScale});
` : ''}
  camera.lookAt(model.position);

  mesh = model;
});

const animate = () => {
  if (mesh) {
    mesh.rotateY(Math.PI / 360);
  }

  renderer.render(scene, camera);

  requestAnimationFrame(animate);
};

animate();

document.body.appendChild(renderer.domElement);
`;
        const codePenData = {
          title: 'New Codepen',
          js: codeTemplate,
          html: '<!-- Generated via TreasureChest: https://treasurechest.nl/ -->',
          css: `body {
  margin: 0;
}`,
          editors: '1010'
        };

        const requestForm = document.createElement('form');
        requestForm.setAttribute('action', 'https://codepen.io/pen/define');
        requestForm.setAttribute('method', 'POST');
        requestForm.setAttribute('target', '_blank');

        const requestBody = document.createElement('input');
        requestBody.setAttribute('type', 'hidden');
        requestBody.setAttribute('name', 'data');
        requestBody.setAttribute('value', JSON.stringify(codePenData));

        requestForm.appendChild(requestBody);
        document.body.appendChild(requestForm);
        requestForm.submit();
      }}
    >
      <i className="fab fa-fw fa-codepen" /> Open in Codepen
    </Menu.Item>}
    {owner && <Menu.Item onClick={() => {
      Modal.confirm({
        title: 'Confirm removal',
        content: 'Delete file and its public URL permanently?',
        onOk: () => {
          const filename = url.split('/').splice(-1, 1)[0];

          handleFileDelete(filename);
        }
      })
    }}>
      <WarnLabel>
        <i className="fa fa-fw fa-ban" /> Remove file
      </WarnLabel>
    </Menu.Item>}
  </Menu>
);

export const WtlShareBox = ({ file, modelScale, owner, offsetY }) => (
  <ShareWrapper offsetY={offsetY}>
    <ShareFlex>
      <input type="text" readOnly={true} value={file} />
      <Dropdown
        overlay={shareMenu(file.replace('https:', ''), `${file}`.split('.').splice(-1, 1)[0].toLowerCase(), modelScale, owner)}
      >
        <CopyIcon onClick={() => {
          navigator.clipboard.writeText(file).then(() => {
            message.success('URL copied to clipboard.');
          }).catch(() => {
            message.warning('Could not copy URL to clipboard. Please try again or copy manually.');
          });
        }}>
          <i className="fas fa-fw fa-copy" />
        </CopyIcon>
      </Dropdown>
    </ShareFlex>
    <Note>
      This URL is public. Use it to access this file.
    </Note>
  </ShareWrapper>
);
