import React from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';

const ColorPreview = styled.div`
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  min-height: 25px;
  margin: 0;
  margin-top: 8px;
  padding: 2px 8px;
  box-sizing: border-box;
  line-height: 1em;
  font-size: 11px;
  background-color: ${p => p.color || '#ffffffff'};
  border: solid 1px #999;
  border-radius: 0;
  outline: none;
  cursor: pointer;
`;

const ColorInput = styled.div`
  position: absolute;
  top: -20px;
  left: -5px;
  z-index: 1000;
  cursor: default;
`;

export class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  render() {
    const { color, onChange, rawValues } = this.props;
    const defaultColor = '#ffffffff';

    return (
      <>
        <ColorPreview
          color={color || defaultColor}
          onClick={() => {
            this.setState({ expanded: !this.state.expanded });
          }}
        >
        </ColorPreview>
        {this.state.expanded &&
            <ColorInput>
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => {
                  this.setState({ expanded: false });
                }}
              ></div>
              <ChromePicker
                color={color || defaultColor}
                onChangeComplete={e => {
                  if (!rawValues) {
                    const newColor = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a || 1})`;
                    onChange(newColor);
                  } else {
                    onChange(e.rgb);
                  }
                }}
              />
            </ColorInput>
          }
      </>
    );
  }
}
