export const getFileFormat = (file) => {
  const _f = file;

  let fileMinPath = _f.split('.');
  const fileExtension = fileMinPath.splice(-1, 1)[0];

  const isImage = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp'].includes(fileExtension);
  const isFont = [ 'otf', 'ttf', 'woff', 'woff2' ].includes(fileExtension);
  const is3DModel = [ 'gltf', 'fbx', 'obj', 'glb' ].includes(fileExtension);

  return { isImage, isFont, is3DModel };
}