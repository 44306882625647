import React from 'react';
import styled from 'styled-components';
import { Modal, Row, Select } from 'antd';
import { VariableInput } from '../variable-input';
import VariablesService from '../../../wtl-schema/services/variable.service';
import StateService from '../../services/state.service';

const { Option } = Select;

export class WtlVariableModal extends React.Component {
  state = {
    variable: undefined
  };

  render() {
    const { onClose, onSelect } = this.props;
    const { variable } = this.state;
    
    return (
      <Modal
        title={
          <>
            Select Variable
          </>
        }
        visible
        closable
        maskClosable
        width={380}
        onOk={() => onSelect(variable)}
        onCancel={() => onClose()}
      >
        <Row gutter={4}>
          <Select
            search
            showArrow={false}
            placeholder="Select..."
            dropdownMatchSelectWidth={false}
            style={{
              width: '100%'
            }}
            onChange={(value) => {
              this.setState({ variable: value });
            }}
            name="variable"
            value={this.state.variable}
          >
            {Object.keys((VariablesService.vars || {}))
              .concat(Object.keys(StateService.getConfig().initialVariables || {}))
              .filter((item, index, array) => array.indexOf(item) === index)
              .map((key, index) => ({
                key: index,
                name: key,
                value: VariablesService.getVar(key)
              })).map(({ key, name, value }) => (
              <Option value={name}>
                <i className="fal fa-fw fa-link" style={{ fontSize: 12 }} /> {name}
              </Option>
            ))}
          </Select>
        </Row>
        <Row gutter={4}>
          <i style={{ fontSize: 9 }}>You can create new variables in <b>settings</b> <i className="fal fa-arrow-right" /> runtime properties.</i>
        </Row>
      </Modal>
    );
  }
}
