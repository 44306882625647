import React from 'react';
import styled from 'styled-components';

const PositionPickerWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 75px;
  height: 75px;
  margin: 0;
  margin-top: 8px;
  padding: 2px 8px;
  box-sizing: border-box;
`;

const PositionSelector = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  border: solid 1px #ccc;
  border-radius: 6px;
  transform: translateX(-50%) translateY(-50%);
`;

const PositionSelectorHandle = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: solid 1px #999;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;

  ${p => p.position === 'top left' && `
    top: 0;
    left: 0;
    transform: translateX(-25%) translateY(-25%);
  `}

  ${p => p.position === 'top center' && `
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-25%);
  `}

  ${p => p.position === 'top right' && `
    top: 0;
    right: 0;
    transform: translateX(25%) translateY(-25%);
  `}

  ${p => p.position === 'center left' && `
    top: 50%;
    left: 0;
    transform: translateX(-25%) translateY(-50%);
  `}
  
  ${p => p.position === 'center center' && `
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  `}

  ${p => p.position === 'center right' && `
    top: 50%;
    right: 0;
    transform: translateX(25%) translateY(-50%);
  `}

  ${p => p.position === 'bottom left' && `
    bottom: 0;
    left: 0;
    transform: translateX(-25%) translateY(25%);
  `}

  ${p => p.position === 'bottom center' && `
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(25%);
  `}

  ${p => p.position === 'bottom right' && `
    bottom: 0;
    right: 0;
    transform: translateX(25%) translateY(25%);
  `}

  ${p => p.selected && `
    border: solid 1px #1890ff;
    background-color: #1890ff;
  `}
`;

const allowedPositions = [
  'top left',
  'top center',
  'top right',
  'center left',
  'center center',
  'center right',
  'bottom left',
  'bottom center',
  'bottom right',
];

export const PositionPicker = (props) => {
  const { value, onChange } = props;

  return (
    <PositionPickerWrapper>
      <PositionSelector>
        {
          allowedPositions.map(position => (
            <PositionSelectorHandle
              position={position}
              selected={value === position}
              onClick={() => onChange(position)}
            />
          ))
        }
      </PositionSelector>
    </PositionPickerWrapper>
  )
};