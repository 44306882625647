import React from 'react';
import styled from 'styled-components';
import { message, Spin } from 'antd';
import { callApi } from '../../../config';
import { authTokenKey } from '../../services/editor.service';
import { getFileFormat } from '../../utils/file-helpers';
import StateService from '../../services/state.service';

const PreviewImage = styled.div`
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url(${p => p.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PreviewFont = styled.div`
  @font-face {
    font-family: "${p => p.url.split('.').splice(-2, 1)[0]}";
    src: url(${p => p.url}) format(${p => ({
      'ttf': '"truetype"',
      'otf': '"opentype"',
      'woff': '"woff"',
      'woff2': '"woff2"'
    })[p.url.split('.').splice(-1, 1)[0]]});
  }

  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "${p => p.url.split('.').splice(-2, 1)[0]}";
  font-size: 32px;
  line-height: 0;
  transform: translateX(-50%) translateY(-50%);
`;

const PreviewModel = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  line-height: 0;
  letter-spacing: 1px;
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000,
     0px 1px 0 #000,
     0px -1px 0 #000,
     1px 0px 0 #000,
     -1px 0px 0 #000;
  overflow: hidden;

  img {
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  div {
    z-index: 2;
  }

  img, div {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const PreviewMissing = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  white-space: nowrap;
`;

const CenteredLabel = styled.div`
  display: block;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
`;

const FileListWrapper = styled.div`
  display: block;
  overflow: hidden;
  height: 100%;
`;

const FileList = styled.div`
  display: block;
  overflow: scroll;
  height: 100%;
`;

const FileItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px;
  box-sizing: border-box;
  border-bottom: solid 1px #333333;
  transition: background-color .1s ease;

  ${p => p.selected ? `
    background-color: #e1effa;
  ` : `
    &:hover {
      cursor: pointer;
      background-color: #e1effa;
    }
  `}
`;

const FileIcon = styled.div`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  border: solid 1px #333;
  overflow: hidden;
  filter: drop-shadow(-4px 4px 6px #cccccc);
  background-color: ${p => p.background || 'transparent'};
`;

const FileName = styled.div`
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  direction: rtl;
  margin-left: 15px;
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  border-bottom: solid 1px #333;

  input {
    background-color: #eee;
    color: #333;
    border-radius: 25px;
    border: solid 1px #ccc;
    padding: 5px 10px;
    margin-left: 10px;

    &:active, &:focus {
      outline: none;
      border: solid 1px #108ee9;
    }
  }
`;

export class WtlFileList extends React.Component {
  constructor(props) {
    super(props);

    this.uploadInput = React.createRef();
    this.debounceSearch = null;
    this.state = {
      loaded: false,
      files: [],
      filterFiles: ''
    };
  }

  componentDidMount() {
    this.loadFileList();

    StateService.onFileListChanged((newFile, removeFile) => {
      const { onFileSelected } = this.props;

      if (newFile) {
        this.state.files.unshift(newFile);
      }

      if (removeFile) {
        this.state.files = this.state.files.filter(file => !file.endsWith(removeFile));

        if (onFileSelected) {
          onFileSelected(null, false);
        }
      }

      this.forceUpdate();
    });
  }

  async loadFileList() {
    const { filterTypes } = this.props;

    if (typeof window === 'undefined') {
      this.setState({
        loaded: true,
        files: []
      });

      return;
    }

    try {
      const { items } = await callApi(
        'project/upload',
        'GET',
        {
          f: filterTypes ? encodeURIComponent(filterTypes) : undefined
        },
        {
          authorization: `Bearer ${sessionStorage[authTokenKey]}`
        }
      );

      this.setState({
        loaded: true,
        files: (items || [])
          .filter(item => (item.split('.').splice(-2, 1) || [])[0] !== 'min')
      });
    } catch {
      message.error('Cannot access files. Try again later.');
      return;
    }
  }

  renderSearchBox() {
    return (
      <SearchBox>
        <i className="fas fa-fw fa-search" />
        <input
          type="text"
          placeholder="Search files"
          onChange={(e) => {
            if (this.debounceSearch) {
              clearTimeout(this.debounceSearch);
            }

            const newSearch = e.target ? e.target.value : '';

            this.debounceSearch = setTimeout(() => {
              this.setState({ filterFiles: newSearch });
            }, 1000);
          }}  
        />
      </SearchBox>
    );
  }

  hashBackgroundColor(text) {
    const colorPalette = [
      'f1c5c5',
      'faf0af',
      'e5edb7',
      '8bcdcd'
    ];

    let hash = 5381;

    for (let i = 0; i < text.length; i++) {
      hash = ((hash << 5) + hash) + text.charCodeAt(i);
    }

    return `#${colorPalette[hash % colorPalette.length]}`;
  }

  renderFiles() {
    const { files, filterFiles, loaded } = this.state;
    const { onFileSelected, selectedFile } = this.props;

    if (!loaded) {
      return (
        <CenteredLabel>
          <Spin
          tip="Loading files"
            style={{ marginTop: '40px' }}
            spinning={true}
          />
        </CenteredLabel>
      );
    }

    return (
      <FileList>
        {files
        .map(file => {
          if (!file.includes(filterFiles)) {
            return null;
          }

          let fileMinPath = file.split('.');
          const extension = fileMinPath.splice(-1, 1)[0];

          const fileId = file.split('/').splice(-1, 1)[0];

          if ([
            '57a4f7ad-bc47-46c8-98a1-48597009932e.png',
            '59ee009a-6458-469a-a0da-c8e8f7b9aca4.png',
            '71f7ac4f-b852-4015-9246-f23f12f896ac.png',
            'a55588bf-010c-41f3-9643-97c43416b857.png',
            'b66ef8e4-80d6-488f-bde5-4b0eb75a70c9.png',
          ].includes(fileId)) {
            return null;
          }

          const { isImage, isFont, is3DModel } = getFileFormat(file);

          fileMinPath = fileMinPath.concat([ 'min', 'jpg' ]).join('.');

          let preview;

          if (isImage) {
            preview = (
              <PreviewImage url={fileMinPath} />
            );
          } else if (is3DModel) {
            preview = (
              <PreviewModel url={file}>
                <img
                  src={fileMinPath}
                  onLoad={(e) => {
                    const parentNode = e.target.parentNode;
                    
                    if (parentNode) {
                      const labelDiv = parentNode.querySelector('.label-text');

                      if (labelDiv) {
                        labelDiv.style.color = `#${this.hashBackgroundColor(file)}`;
                      }
                    }
                  }}
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                />
                <div className="label-text">
                  3D
                </div>
              </PreviewModel>
            );
          } else if (isFont) {
            preview = (
              <PreviewFont url={file} fontId={file}>
                <div>Aa</div>
              </PreviewFont>
            )
          } else {
            preview = (
              <PreviewModel>
                <div>{extension}</div>
              </PreviewModel>
            );
          }

          return (
            <FileItem
              key={file}
              selected={selectedFile === file}
              onClick={() => {
                if (onFileSelected) {
                  onFileSelected(file, is3DModel ? true : false);
                }
              }}
            >
              <FileIcon background={this.hashBackgroundColor(file)}>
                {preview}
              </FileIcon>
              <FileName>
                {fileId}
              </FileName>
            </FileItem>
          );
        })}
      </FileList>
    );
  }

  render() {
    const {
      selection,
      onFileSelected,
      onFileUpload,
      inputProps
    } = this.props;

    return (
      <FileListWrapper>
        {this.renderSearchBox()}
        {this.renderFiles()}
      </FileListWrapper>
    );
  }
}